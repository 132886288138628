import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { getActiveLanguage } from 'react-localize-redux';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { modalClose } from '../../assets/icons/svgIcons';
import { Portal } from '../Portal';

import modalClass from '../../modules/shared/modalClass';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';
import {withLastLocation} from "react-router-last-location";

class ModalWrapper extends React.PureComponent {
  componentDidMount() {
    document.body.classList.add('no-scroll');
  }

  componentWillUnmount() {
    document.body.classList.remove('no-scroll');
  }

  handleClick = (e, history, lastLocation, handleModalClose) => {
    if (!lastLocation) {
      history.push(`${this.props.languagePrefix}/`);
      return;
    }
    if (handleModalClose) {
      e.stopPropagation();
      document.body.classList.remove('no-scroll');
      modalClass.hideModal();
      handleModalClose();
    } else if (this.props.location.key) {
      e.stopPropagation();
      document.body.classList.remove('no-scroll');
      modalClass.hideModal();
      history.goBack();
    } else {
      e.stopPropagation();
      document.body.classList.remove('no-scroll');
      modalClass.hideModal();
      history.push(`${this.props.languagePrefix}/`);
    }
  };

  render() {
    const {
      children,
      history,
      lastLocation,
      addClass = '',
      handleModalClose = null,
      isProduct,
      isFixed,
      modalId,
      isBarShown,
    } = this.props;

    if (!modalId) {
      throw Error('Missing required prop "modalId" in ModalWrapper');
    }

    return (
      <Portal selector="#root" portalId={modalId}>
        <StyledModal className="modal-wrapper" isFixed={isFixed} isBarShown={isBarShown}>
          <div className={`modal-content ${addClass}`}>
            {children}
            <button
              type="button"
              className={isProduct ? 'close close--product' : 'close'}
              onClick={(e) => this.handleClick(e, history, lastLocation, handleModalClose)}
            >
              {modalClose}
            </button>
          </div>
        </StyledModal>
      </Portal>
    );
  }
}

const StyledModal = styled.div`
  --hover-color: #E74E1A;

  z-index: 100;
  position: fixed;
  width: 100vw;
  height: ${(props) => (props.isBarShown ? 'calc(100% - 60px)' : '100%')};
  display: block;
  visibility: visible;
  top: ${(props) => (props.isBarShown ? '60px' : '0')};
  left: 0;
  background: #0A0A0A;
  overflow: auto;
  
  .modal-content {
    height: 100%;
  }

  .close {
    align-self: flex-end;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    padding: 1.4rem;
    transition: var(--transition-time);
    position: ${(props) => (props.isFixed ? 'fixed' : 'absolute')};
    top: ${(props) => (props.isBarShown ? '60px' : '0')};
    right: 0;
    outline: none;
    z-index: 101;
    
    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
      
    path {
      transition: var(--transition-time);
    }
      
    &:hover {
      cursor: pointer;
      transition: var(--transition-time);
      box-shadow: 0 0 100px rgba(255, 0, 0, .1) inset,
                  0 0 30px rgba(255, 0, 0, .1);
        
      path {
        fill: var(--hover-color);
        transition: var(--transition-time);
      }
    }
  }
  
  @media all and (min-width: 769px) {
    .close {
      top: 1rem;
      right: 3rem;
      width: auto;
      height: auto;
      
      &--product {
        top: 3.8rem;
      }
      
      svg {
        width: auto;
        height: auto;
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  activeLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  isBarShown: state.appBar,
});

export default withRouter(connect(mapStateToProps, {})(withLastLocation(ModalWrapper)));
