import React from 'react';
import styled from 'styled-components';
import { reset } from 'redux-form';

import JobOfferForm from './JobOfferForm';
import ModalWrapper from '../shared/ModalWrapper';
import JobOfferModal from './offerModal/JobOfferModal';

import keyByLang from '../../modules/shared/keyByLang';

import jobsBanner from '../../assets/images/JobsBanner.jpg';
import fixImagePath from '../../modules/shared/fixImagePath';

class JobOffersPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
      activeVacancyId: null,
      chosenVacancyId: null,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.applyForJob = this.applyForJob.bind(this);
    this.chooseVacancyId = this.chooseVacancyId.bind(this);
  }

  toggleModal(id) {
    this.setState(prevState => ({
      isModalVisible: !prevState.isModalVisible,
      activeVacancyId: id,
    }));
  }

  chooseVacancyId(id) {
    this.setState({ chosenVacancyId: id });
  }

  applyForJob(data, e, form) {
    const { translate } = this.props;
    const jobApply = new FormData();
    const phone = data.username.slice(data.username.indexOf('0'));
    jobApply.append('Resumes[vacancy_id]', this.state.chosenVacancyId);
    jobApply.append('Resumes[fio]', data.name);
    jobApply.append('Resumes[phone]', phone);
    jobApply.append('Resumes[email]', data.email);
    jobApply.append('Resumes[comment]', data.message);
    jobApply.append('Resumes[uploadedFile]', data.cv);
    this.props.sendCV(jobApply).then((res) => {
      if (res.status) {
        this.props.showSnackBar(translate('DataSentSuccessfully'), 3000, true);
        form.reset();
      }
    }).catch((error) => {
      if (error.message === 'Network Error') {
        this.props.showSnackBar(translate('DataNotSent'), 3000, false);
      }
    });
  }

  render() {
    const {
      currentLanguage, vacancies, vacancyReviews, settings, translate,
    } = this.props;
    const { isModalVisible, activeVacancyId } = this.state;
    const title = keyByLang('title', currentLanguage);
    const name = keyByLang('name', currentLanguage);
    const reviewText = keyByLang('review', currentLanguage);
    const position = keyByLang('position', currentLanguage);
    const description = keyByLang('description', currentLanguage);

    let imagePath = null;
    let pageData = null;

    if (settings && settings.syspages) {
      pageData = settings.syspages.find(page => page.page_const === 'vacancies');
      imagePath = fixImagePath(pageData);
    }

    return (
      <StylizedJobs className="jobs" bannerImage={imagePath || jobsBanner}>
        {isModalVisible ? (
          <ModalWrapper handleModalClose={this.toggleModal} modalId="JobOfferModal">
            <JobOfferModal
              vacancies={vacancies}
              activeVacancyId={activeVacancyId}
              currentLanguage={currentLanguage}
              validate={JobOffersPage.validateForm}
            />
          </ModalWrapper>
        ) : null}
        <div className="jobs__header" />
        <div className="jobs__content">
          <h1 className="jobs__heading jobs__heading--main">
            {(pageData && pageData[title]) || translate('Vacancies')}
          </h1>
          <hr className="solid" />
          <h3 className="jobs__heading jobs__heading--secondary">{translate('LookingForEmployees')}</h3>
          <p className="jobs__text">{(pageData && pageData[description]) || ''}</p>
          <div className="jobs__types-wrap">
            {vacancies && vacancies[0] ? vacancies.map(vacancy => (
              <div className="jobs__type" key={vacancy.id} onClick={() => this.toggleModal(vacancy.id)}>
                <figure className="job">
                  <div className="job__icon-wrap">
                    <img
                      className="job__icon"
                      src={vacancy.image_filename}
                      alt="icon"
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                  <figcaption className="job__name">{vacancy[title]}</figcaption>
                </figure>
              </div>
            )) : null}
          </div>
          <hr className="dashed" />
          <div className="jobs__feedbacks">
            <h3 className="jobs__heading jobs__heading--third">{translate('FeedbackOfEmployees')}</h3>
            <div className="jobs__feedbacks-wrap">
              {vacancyReviews && vacancyReviews[0] ? vacancyReviews.map(review => (
                <div className="feedback jobs__feedback" key={review.id}>
                  <img
                    className="feedback__image"
                    src={review.image_filename}
                    alt="person"
                    decoding="async"
                    loading="lazy"
                  />
                  <div className="feedback__head">
                    <p className="feedback__name">{review[name]}</p>
                    <hr className="dashed feedback__line" />
                    <p className="feedback__type">{review[position]}</p>
                  </div>
                  <p className="feedback__text">{review[reviewText]}</p>
                </div>
              )) : null}
            </div>
          </div>
          <hr className="dashed" />
          <div className="jobs__form-wrap">
            <h3 className="jobs__heading jobs__heading--third">{translate('BecomePartOfTheTeam')}</h3>
            <p className="jobs__text">
              {translate("VacancyText")}
            </p>
            <div className="jobs__form">
              <JobOfferForm
                currentLanguage={currentLanguage}
                vacancies={vacancies}
                onSubmit={this.applyForJob}
                chooseVacancyId={this.chooseVacancyId}
                translate={translate}
              />
            </div>
          </div>
        </div>
      </StylizedJobs>
    );
  }
}

const StylizedJobs = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --hover-background: #313131;
  
  .jobs {
    
    &__header {
      height: 130px;
      text-align: center;
      background: radial-gradient(630.48px at 50.41% 50.71%, rgba(0, 0, 0, 0.1) 0%, #000000 100%),
                  url(${props => (props.bannerImage ? props.bannerImage : 'null')});
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
    }
    
    &__content {
      max-width: 630px;
      width: 100%;
      padding: 0 15px;
      margin: 0 auto;
    }
    
    &__heading {
      text-align: center;
      font-weight: 100;
      text-transform: none;
      
      &--main {
        padding: 1.25rem 0 1rem;
        font-size: 1.5rem;
      }
      
      &--secondary {
        font-size: 1.2rem;
        padding: 2rem 1rem 1rem;
      }
      
      &--third {
        font-size: 1.4rem;
        padding: 2.5rem 0 1.3rem;
      }
    }
    
    &__text {
      color: var(--text-color);
      text-align: justify;
    }
    
    &__types-wrap {
      padding: 2rem 0 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }
    
    &__type {
      flex-basis: calc(100% / 3);
      display: flex;
      justify-content: center;
      padding-bottom: 1.5rem;
    }
    
    &__feedbacks-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 1.5rem;
    }
    
    &__feedback {
      flex-basis: calc(100% / 2 - 2%);
      flex-grow: 0;
    }
    
    &__form {
      max-width: 440px;
      margin: 0 auto;
      padding: 2.5rem 0;
    }
  }
  
  .job {
    max-width: 120px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &:hover {
      cursor: pointer;
      
      .job__icon-wrap {
        background-color: var(--hover-background);
        border-color: var(--active-text-color);
      }
      
      .job__icon {
        opacity: 1;
      }
      
      .job__name {
        color: var(--active-text-color);
      }
    }
    
    &__icon-wrap {
      width: 90px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--text-color);
      border-radius: 4px;
    }
    
    &__icon {
      opacity: .6;
    }
    
    &__name {
      text-align: center;
      padding-top: .75rem;
      color: var(--text-color);
      font-size: .9rem;
    }
  }
  
  .feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .5rem 0 1.5rem;
    
    &__image {
      border-radius: 4px;
      max-width: 122px;
      object-fit: contain;
    }
    
    &__name {
      padding: 1.4rem  0 .8rem;
      text-align: center;
    }
    
    &__type {
      color: var(--text-color);
      font-weight: 700;
      text-align: center;
      padding: .6rem;
    }
    
    &__text {
      color: var(--text-color);
      font-size: .75rem;
      text-align: center;
    }
  }
  
@media all and (min-width: 769px) {
  .jobs {
    &__header {
      height: 250px;
    }
    
    &__heading {
      
      &--main {
        padding: 2.5rem 0 1.3rem;
      }
      
      &--secondary {
        font-size: 1.4rem;
        padding: 2.5rem 1.3rem;
      }
      
      &--third {
        padding: 4rem 0 1.3rem;
      }
    }
    
    &__types-wrap {
      width: 120%;
      padding: 4rem 0 2rem;
      margin-left: -10%;
    }
    
    &__types {
      padding-bottom: 2.5rem;
    }
  
    &__text { 
      text-align: center;
    }
    
    &__feedbacks-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 2.5rem;
    }
    
    &__feedback {
      flex-basis: calc(100% / 3 - 2%);
      flex-grow: 0;
    }
  }
  
  .job {
    &__icon-wrap {
      width: 120px;
      height: 120px;
    }
    
    &__name {
      padding-top: 1rem;
      font-size: 1rem;
    }
  }
  
  .feedback {
    &__text {
      font-size: .9rem;
    }
  }
}
`;

export default JobOffersPage;
